<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="
            col-7
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          "
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.materials_consuption") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
          </div>
          <div>
            <el-select
              size="mini"
              @clear="getAll()"
              v-model="warehouseId"
              clearable
              :placeholder="$t('message.warehouses_0')"
            >
              <el-option
                v-for="item in warehouses"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div
          class="
            col-5
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <export-excel
            v-can="'patients.excel'"
            class="btn excel_btn"
            :data="list"
            :fields="excel_fields"
            @fetch="controlExcelData()"
            worksheet="Расход материали "
            name="Расход_материали.xls"
          >
            <el-button size="mini" icon="el-icon-document-delete">
              Excel
            </el-button>
          </export-excel>
          <el-dropdown class="mr-2">
            <el-button size="mini" icon="el-icon-setting">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(column, index) in columns" :key="index">
                <el-checkbox
                  :checked="column.show"
                  @change="column.show = !column.show"
                  >{{ column.title }}</el-checkbox
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>
        <thead>
          <tr>
            <th v-if="columns.id.show">{{ columns.id.title }}</th>
            <th v-if="columns.name.show">{{ columns.name.title }}</th>
            <th v-if="columns.code.show">{{ columns.code.title }}</th>
            <th v-if="columns.sku.show">{{ columns.sku.title }}</th>
            <th v-if="columns.category.show">{{ columns.category.title }}</th>
            <th v-if="columns.available.show">{{ columns.available.title }}</th>
            <th v-if="columns.measurement.show">
              {{ columns.measurement.title }}
            </th>
            <th v-if="columns.vat.show">{{ columns.vat.title }}</th>
            <th v-if="columns.created_at.show">
              {{ columns.created_at.title }}
            </th>
            <th v-if="columns.updated_at.show">
              {{ columns.updated_at.title }}
            </th>
            <th v-if="columns.settings.show">{{ columns.settings.title }}</th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.name.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.name"
                :placeholder="columns.name.title"
              ></el-input>
            </th>
            <th v-if="columns.code.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.code"
                :placeholder="columns.code.title"
              ></el-input>
            </th>
            <th v-if="columns.sku.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.sku"
                :placeholder="columns.sku.title"
              ></el-input>
            </th>
            <th v-if="columns.category.show">
              <categories v-model="filterForm.category_id"></categories>
            </th>
            <th v-if="columns.available.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.available"
                :placeholder="columns.available.title"
              ></el-input>
            </th>
            <th v-if="columns.measurement.show">
              <measurements v-model="filterForm.measurement_id"></measurements>
            </th>
            <th v-if="columns.vat.show">
              <el-input
                v-model="filterForm.vat"
                filterable
                clearable
                :placeholder="columns.vat.title"
                size="mini"
              ></el-input>
            </th>
            <th v-if="columns.created_at.show">
              <el-date-picker
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                v-model="filterForm.created_at"
                size="mini"
                clearable
                :placeholder="columns.created_at.title"
              ></el-date-picker>
            </th>
            <th v-if="columns.updated_at.show">
              <el-date-picker
                type="date"
                v-model="filterForm.updated_at"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                size="mini"
                clearable
                :placeholder="columns.updated_at.title"
              ></el-date-picker>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>
        <transition-group name="flip-list" tag="tbody">
          <tr
            v-for="remainder in list"
            :key="remainder.id"
            class="cursor-pointer"
          >
            <td v-if="columns.id.show">{{ remainder.id }}</td>
            <td v-if="columns.name.show">{{ remainder.name }}</td>
            <td v-if="columns.code.show">{{ remainder.code }}</td>
            <td v-if="columns.sku.show">{{ remainder.sku }}</td>
            <td v-if="columns.category.show">{{ remainder.category.name }}</td>
            <!-- <td v-if="columns.available.show">{{ remainder.available ? remainder.available : "0"}}</td>
                    <td v-if="columns.available.show">{{ remainder.received ? remainder.received : "0"}}</td> -->
            <td v-if="columns.available.show">
              {{
                remainder.available || remainder.received
                  ? remainder.received - remainder.available
                  : "0"
              }}
            </td>
            <td v-if="columns.measurement.show">
              {{ remainder.main_measurement.name }}
            </td>
            <td v-if="columns.vat.show">{{ remainder.vat }}</td>
            <td v-if="columns.created_at.show">{{ remainder.created_at }}</td>
            <td v-if="columns.updated_at.show">{{ remainder.updated_at }}</td>
            <td v-if="columns.settings.show" class="settings-td">
              <router-link
                :to="{
                  name: 'productResidues.index',
                  params: { id: remainder.id, warehouse_id: warehouseId },
                }"
              >
                <el-button size="small" icon="el-icon-folder" type="success">{{
                  $t("message.detail")
                }}</el-button>
              </router-link>
            </td>
          </tr>
        </transition-group>
      </table>
      <el-drawer
        size="90%"
        :visible.sync="drawer.warehouseDetails.status"
        :with-header="true"
        :ref="drawer.warehouseDetails.name"
        @opened="drawerOpened(drawer.warehouseDetails.component)"
      >
        <crm-coming
          :drawer-name="drawer.warehouseDetails.component"
          :warehouse-id="warehouseId"
          :product="selectedItem"
          :ref="drawer.warehouseDetails.component"
        ></crm-coming>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import categories from "@/components/inventory-select/categories";
import measurements from "@/components/inventory-select/measurements";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import CrmComing from "./components/crm-coming";

export default {
  mixins: [list, drawer],
  components: {
    CrmComing,
  },
  data() {
    return {
      warehouseId: this.$route.params.warehouse_id
        ? this.$route.params.warehouse_id
        : "",
      excel_fields: {},
      loadingData: false,
      selectedItem: null,
      drawer: {
        warehouseDetails: {
          name: "warehouses",
          status: false,
          component: "warehouseDetails",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "remainders/list",
      columns: "remainders/columns",
      pagination: "remainders/pagination",
      filter: "remainders/filter",
      sort: "remainders/sort",
      warehouses: "warehouses/inventory",
    }),
  },
  async created() {},
  async mounted() {
    this.controlExcelData();
    if (this.warehouses && this.warehouses.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
    warehouseId: {
      handler: function (val) {
        const query = {
          ...this.filter,
          ...this.pagination,
          ...this.sort,
          warehouse_id: val,
        };
        if (!this.loadingData) {
          this.loadingData = true;
          this.getRemainders(query)
            .then((response) => {
              this.loadingData = false;
            })
            .catch((error) => {
              this.loadingData = false;
            });
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getRemainders: "remainders/index",
      updateSort: "remainders/updateSort",
      updateFilter: "remainders/updateFilter",
      updateColumn: "remainders/updateColumn",
      updatePagination: "remainders/updatePagination",
      showRemainder: "remainders/show",
      empty: "remainders/empty",
      delete: "remainders/destroy",
      refresh: "remainders/refreshData",
      getInventory: "warehouses/inventory",
    }),
    fetchData() {
      if (this.warehouseId == "") {
        const query = {
          ...this.filter,
          ...this.pagination,
          ...this.sort,
          warehouse_id: this.warehouseId,
        };
        if (!this.loadingData) {
          this.warehouseId = "";
          this.loadingData = true;
          this.getRemainders(query)
            .then((response) => {
              this.loadingData = false;
            })
            .catch((error) => {
              this.loadingData = false;
            });
        }
      }
    },
    getAll() {
      this.warehouseId == "";
      this.fetchData();
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    details(model) {
      this.selectedItem = model;
      this.drawer.warehouseDetails.status = true;
    },
    drawerOpened(ref) {
      this.$refs[ref].opened();
    },
  },
};
</script>
